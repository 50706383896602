import s from "./RiskProfileComponent.module.css";
import { useEffect, useState, useMemo } from "react";
import { get, set } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveTab } from "../../../action/actionMap";
import { URL_SIDE_BAR } from "../../../constants/map.constants";
import { clearOnUnmount } from "../../../action/actionMft";
import { fetchAvailablePeriodRequest, fetchRiskTop } from "../../../action/actionRisk";
import PeriodDropdown from "../../../components/PeriodDropdown/PeriodDropdown";
import RiskKRIChartComponent from "./RiskKRIChartComponent";
import RiskMitigationChartComponent from "./RiskMitigationChartComponent";
import RiskInfoCallout from "../../../components/Callout/RiskInfoCallout";

const RiskProfileComponent = () => {
  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(false);
  const latestPeriod = useSelector((state) => get(state, 'risk.availablePeriod.result', {}));
  const [currentQuarter, setCurrentQuarter] = useState({ year: latestPeriod.lastestYear, quarter: latestPeriod.lastestPeriod });

  const handleLoad = () => {
    setIsLoaded(true);
  };

  const riskInfo = useSelector((state) =>
    get(state, 'risk.riskInfo.result', [])
  );

  const headers = [
    { tooltipContent: <img src="img/icon-score-card/tooltip_ranking.svg" alt="Ranking Tooltip" />},
    { tooltipContent: <img src="img/icon-score-card/tooltip_KRI.svg" alt="KRI Tooltip" />},
    { tooltipContent: <img src="img/icon-score-card/tooltip_mitigation.svg" alt="Mitigation Tooltip" />},
    { tooltipContent: <img src="img/icon-score-card/tooltip_outlook.svg" alt="S&O Tooltip" />},
  ];

  const onDateChange = (date) => {
    setCurrentQuarter(date);
  };

  const TableHeaderWithIcon = ({ text, tooltipContent }) => (
    <th className={text !== "Statement & Outlook" ? s.headerSmall : ''}>
      <span style={{ fontWeight: 100 }}>{text} </span>
      <div className={s.tooltip}>
        <img alt='info' src='/img/icon/ic_info_outline.svg'/>
        <div className={s.headerTooltipContainer}>
          <div className={s.headerTooltip}>{tooltipContent}</div>
        </div>
      </div>
    </th>
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRisk, setSelectedRisk] = useState<{
    ranking: number;
    entity: string;
    statementAndOutlook: string;
    gasTopRisk: string;
    kriStatus: number[];
    mitigation: number[];
  } | null>(null);

  const handleRiskClick = (risk) => {
    setSelectedRisk(risk);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRisk(null);
  };

  const isQ32024OrLater = (quarter: string, year: string) => {
    if (parseInt(year) > 2024) return true;
    if (parseInt(year) === 2024 && quarter >= "Q3") return true;
    return false;
  };

  useEffect(() => {
    dispatch(updateActiveTab(URL_SIDE_BAR.RISK_PROFILE));
    return () => {
      dispatch(clearOnUnmount())
    };
  }, []);

  useEffect(() => {
    if (latestPeriod.lastestYear && latestPeriod.lastestPeriod) {
      setCurrentQuarter({ year: latestPeriod.lastestYear, quarter: latestPeriod.lastestPeriod });
    }
  }, [latestPeriod]);

  useEffect(() => {
    dispatch(fetchRiskTop(`?year=${currentQuarter.year}&quater=${currentQuarter.quarter}`));
  }, [currentQuarter, dispatch]);

  useEffect(() => {
    dispatch(fetchAvailablePeriodRequest());
  }, [dispatch]);

  function getBorderColor(rating) {
    switch (rating) {
      case 'Neutral':
        return s.transparent;
      case 'Low':
        return s.green;
      case 'Medium':
        return s.yellow;
      case 'High':
        return s.orange;
      default:
        return s.red;
    }
  }

  function getMovementIconSrc(movement) {
    if (movement > 0) {
      return '/img/icon-score-card/Arrow Up.svg';
    } else if (movement < 0) {
      return '/img/icon-score-card/Arrow Down.svg';
    } else {
      return '/img/icon-score-card/Arrow duo.svg';
    }
  }

  function getTrendIconSrc(outlookTrend) {
    switch (outlookTrend) {
      case 'Improving':
        return '/img/icon-score-card/Arrow Trend Down.svg';
      case 'Escalating':
        return '/img/icon-score-card/Arrow Trend Up.svg';
      case 'Neutral':
        return '/img/icon-score-card/Arrow duo.svg';
      default:
        return '';
    }
  }

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }

  const availablePeriods = useSelector((state: any) => get(state, 'risk.availablePeriod.result.availablePeriods', []));

	return (
    <div className="riskprofile" onLoad={handleLoad}>
      <div className={`fullscreen-pt-0 ${s.riskProfileRoot}`}>
        <div className={`page-container bg-risk-management ${s.riskProfileContainer}`}>
          <div className={s.riskProfilePanel}>
            <div className={s.riskProfilePanelContainer}>
              <div className={s.riskProfileHeader}>
                {isQ32024OrLater(currentQuarter.quarter, currentQuarter.year) ? (
                  <h2>Gas & Maritime Top Risks Updates</h2>
                ) : (
                  <h2>Gas Business Top Risks Updates</h2>
                )}
                {availablePeriods.length > 0 ? (<PeriodDropdown currentQuarter={currentQuarter} onDateChange={onDateChange} />) : <></>}
              </div>
              <table>
                <thead>
                  <tr className={s.tableHeader} >
                    <TableHeaderWithIcon text="Ranking" tooltipContent={headers[0].tooltipContent}/>
                    {isQ32024OrLater(currentQuarter.quarter, currentQuarter.year) ? (
                      <th style={{fontWeight: 100}}>Gas & Maritime Top Risks</th>
                    ) : (
                      <th style={{fontWeight: 100}}>Gas Top Risk</th>
                    )}
                    <th style={{width:'4%', textAlign: 'center', verticalAlign: 'middle', fontWeight: 100}}>Entity</th>
                    <TableHeaderWithIcon text="KRI Status" tooltipContent={headers[1].tooltipContent}/>
                    <TableHeaderWithIcon text="Mitigations" tooltipContent={headers[2].tooltipContent}/>
                    <TableHeaderWithIcon text="Statement & Outlook" tooltipContent={headers[3].tooltipContent}/>
                  </tr>
                  <tr className={s.greyLine}><td colSpan={6}></td></tr>
                </thead>
                
                <tbody>
                {isLoaded && riskInfo.length > 0 ? (
                  riskInfo.sort((a, b) => a.ranking - b.ranking).map((risk, index) => {
                    let borderColorClass = getBorderColor(risk.rating);

                    return (
                      <tr key={index} className={`${risk.ranking % 2 === 0 ? s.evenRisk : s.oddRisk} ${s.tableRow}`} onClick={() => handleRiskClick(risk)}>
                        <td className={`${s.center} ${s.coloredBar} ${borderColorClass}`}>
                          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div>
                              {risk.ranking}
                            </div>
                            <div className={s.oval} style={{ color: risk.movement > 0 ? "red" : risk.movement < 0 ? "green" : risk.movement === 'NEW' ? "white" : undefined }}>
                              <img alt='movement' src={getMovementIconSrc(risk.movement)}/>
                              {risk.movement !== '-' && <span>{risk.movement}</span>}
                            </div>  
                          </div>
                        </td>
                        <td style={{color:"#00A19C", fontWeight: 700}}>
                          <div className={s.tooltipContainer}>
                            <span>{truncateText(risk.gasTopRisk, 250)}</span>
                            {risk.gasTopRisk.length > 250 && (
                              <div className={s.textTooltip}>
                                {risk.gasTopRisk}
                              </div>
                            )}
                          </div>
                        </td>
                        <td className={s.center}>{risk.entity}</td>
                        <td><div className={s.chartCenter}><RiskKRIChartComponent id={`KRIChart${index}`} data={[risk.kriStatus.baseKri, risk.kriStatus.warningKri, risk.kriStatus.criticalKri, risk.kriStatus.notStartedKRI]}/></div></td>
                        <td><div className={s.chartCenter}><RiskMitigationChartComponent id={`MitigationChart${index}`} data={[risk.mitigation.completedMitigation, risk.mitigation.onTrackMitigation, risk.mitigation.delayedMitigation, risk.mitigation.notStartedMitigation]}/></div></td>
                        <td className={s.changeThis}>
                          <div className={s.tooltipContainer}>
                            <span>{truncateText(risk.statementAndOutlook, 250)}</span>
                            {risk.statementAndOutlook.length > 250 && (
                              <div className={s.textTooltip}>
                                {risk.statementAndOutlook}
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className={s.outlookTrendContainer}>
                            <div className={s.statementOval}>
                              <img 
                                alt={risk.outlookTrend === 'Improving' ? 'Arrow Up' : risk.outlookTrend === 'Escalating' ? 'Arrow Down' : 'Neutral'} 
                                src={getTrendIconSrc(risk.outlookTrend)} />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className={s.noResult}>
                        <img src="/img/icon/no-result.svg" alt="No results" />
                        <h2>No data available!</h2>
                      </div>
                    </td>
                  </tr>
                )}
                </tbody>
                {isModalOpen && selectedRisk && (
                  <RiskInfoCallout
                    risk={selectedRisk}
                    ModalOpen={isModalOpen}
                    onClose={closeModal}
                    year={currentQuarter.year}
                    quarter={currentQuarter.quarter}
                  />
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RiskProfileComponent;
