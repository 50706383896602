import { Table } from 'antd';
import s from '../AssuranceLines.module.css';
import { useEffect, useState } from 'react';

interface CAPOpu {
  opu: string;
  overdue: number;
  notAssigned: number;
}

interface CAPAsset {
  assetName: string;
  overdue: number;
  notAssigned: number;
}

interface assuranceProgramOpu {
  opu: string;
  conducted: number;
  deferred: number;
  planned: number;
}

interface assuranceProgramAsset {
  assetName: string;
  conducted: number;
  deferred: number;
  planned: number;
}

export interface SecondLineGBTableProps {
  assuranceData: {
		correctiveActionPlanOpuBreakdowns: CAPOpu[];
		correctiveActionPlanAssetBreakdowns: CAPAsset[];
    assuranceProgramOpuBreakdowns: assuranceProgramOpu[];
    assuranceProgramAssetBreakdowns: assuranceProgramAsset[];
  };
  opu: number;
  onChangeTab: (tab: number) => void;
  opuTabArr: { label: string, value: number }[];
  type: string;
}
// '/img/icon/greenPopup.svg'
const SecondLineGBTableComponent = ({ assuranceData, opu, type, onChangeTab, opuTabArr }: SecondLineGBTableProps) => {

  const getColumns = () => {
    if (opu === 0 && type === 'CAP') {
      return [
        {
          title: 'OPUs',
          dataIndex: 'opu',
          key: 'opu',
          render: (text, record) => {
            const opuValue = opuTabArr?.find(opu => opu.label === record.opu)?.value ?? 0;
            return (
              <span
                className={`${s.greenText} ${s.underlinedText}`}
                onClick={() => onChangeTab(opuValue)}
                style={{ cursor: 'pointer' }}
              >
                {text}
              </span>
            );
          },
        },
        { title: 'Overdue', dataIndex: 'overdue', key: 'overdue', className: s.rightAlign },
        { title: 'Not Assigned', dataIndex: 'notAssigned', key: 'notAssigned', className: s.rightAlign },
      ];
    } else if (opu === 0 && type === 'assuranceProgram') {
      return [
        {
          title: 'OPUs',
          dataIndex: 'opu',
          key: 'opu',
          render: (text, record) => {
            const opuValue = opuTabArr?.find(opu => opu.label === record.opu)?.value ?? 0;
            return (
              <span
                className={`${s.greenText} ${s.underlinedText}`}
                onClick={() => onChangeTab(opuValue)}
                style={{ cursor: 'pointer' }}
              >
                {text}
              </span>
            );
          },
        },
        { title: 'Planned', dataIndex: 'planned', key: 'planned', className: s.rightAlign },
        { title: 'Conducted', dataIndex: 'conducted', key: 'conducted', className: s.rightAlign },
        { title: 'Deferred', dataIndex: 'deferred', key: 'deferred', className: s.rightAlign },
      ];
    } else if ([1, 2, 3, 4].includes(opu) && type === 'CAP') {
      return [
        { title: 'Assets', dataIndex: 'assetName', key: 'assetName' },
        { title: 'Overdue', dataIndex: 'overdue', key: 'overdue', className: s.rightAlign },
        { title: 'Not Assigned', dataIndex: 'notAssigned', key: 'notAssigned', className: s.rightAlign },
      ];
    } else if ([1, 2, 3, 4].includes(opu) && type === 'assuranceProgram') {
      return [
        { title: 'Assets', dataIndex: 'assetName', key: 'assetName' },
        { title: 'Planned', dataIndex: 'planned', key: 'planned', className: s.rightAlign },
        { title: 'Conducted', dataIndex: 'conducted', key: 'conducted', className: s.rightAlign },
        { title: 'Deferred', dataIndex: 'deferred', key: 'deferred', className: s.rightAlign },
      ];
    }
    return [];
  };

  const mapBreakdowns = (breakdowns: any[], isOpu: boolean) =>
    breakdowns.map((breakdown) => ({
      ...breakdown,
      key: isOpu ? breakdown.opu : breakdown.assetName,
    })
	);

  const dataSource = opu === 0
    ? (type === 'CAP'
			? mapBreakdowns(assuranceData?.correctiveActionPlanOpuBreakdowns ?? [], true)
			: mapBreakdowns(assuranceData?.assuranceProgramOpuBreakdowns ?? [], true))
    : (type === 'CAP'
			? mapBreakdowns(assuranceData?.correctiveActionPlanAssetBreakdowns ?? [], false)
			: mapBreakdowns(assuranceData?.assuranceProgramAssetBreakdowns ?? [], false))
      .filter((item) => item.assetName !== 'PETRONAS Floating LNG 3 Sdn Bhd' && ['PEGT', 'PGB'].includes(item.assetName));

  return (
    <div className={s.breakdownByOpusSection} key={`tab-${opu}-${type}-${Date.now()}`}>
      <div className={s.breakdownByOpusHeader}>
        {opu === 0 ? 'Breakdown By OPUs' : 'Breakdown By Assets'}
      </div>
      <Table
        className="assurance-table"
        rowClassName={(record, index) => (index % 2 === 0 ? s.tableStripe : '')}
        columns={getColumns()}
        dataSource={dataSource}
        pagination={false}
      />
    </div>
  );
};

export default SecondLineGBTableComponent;