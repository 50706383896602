import { Table } from 'antd';
import s from '../AssuranceLines.module.css';

interface BreakdownBase {
  compliancePercentage: number;
  overdue: number;
  notAssigned: number;
  unplanned: number;
}

interface OpuBreakdown extends BreakdownBase {
  opu: string;
}

interface AssetBreakdown extends BreakdownBase {
  assetName: string;
}

export interface FirstLineGBTableProps {
  assuranceData: {
    requirementCompOpuBreakdowns: OpuBreakdown[];
    requirementCompAssetBreakdowns: AssetBreakdown[];
    scheduleCompOpuBreakdowns: OpuBreakdown[];
    scheduleCompAssetBreakdowns: AssetBreakdown[];
  };
  opu: number;
  onChangeTab: (tab: number) => void;
  opuTabArr: { label: string, value: number }[];
  type: string;
}
// '/img/icon/greenPopup.svg'
const FirstLineGBTableComponent = ({ assuranceData, opu, type, onChangeTab, opuTabArr }: FirstLineGBTableProps) => {
  const columns = [
    {
      title: opu === 0 ? 'OPUs' : 'Assets',
      dataIndex: opu === 0 ? 'opu' : 'assetName',
      key: opu === 0 ? 'opu' : 'assetName',
      render: (text, record) => {
        const opuValue = opuTabArr?.find(opu => opu.label === record.opu)?.value ?? 0;
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {opu === 0 ? (
              <span
                className={`${s.greenText} ${s.underlinedText}`}
                onClick={() => onChangeTab(opuValue)}
                style={{ cursor: 'pointer' }}
              >
                {text}
              </span>
            ) : (
              <>
                {text}
                <img
                  src='/img/icon/greenPopup.svg'
                  alt='icon'
                  style={{ marginLeft: '10px', marginBottom: '2px', cursor: 'pointer' }}
                />
              </>
            )}
          </span>
        );
      },
    },
    {
      title: 'Compliance',
      dataIndex: 'compliancePercentage',
      key: 'compliancePercentage',
      className: s.rightAlign,
      render: (text) => text != null ? <span className={s.rightAlign}>{text}</span> : <span className={s.rightAlign}>--</span>,
    },
    {
      title: 'Overdue',
      dataIndex: 'overdue',
      key: 'overdue',
      className: s.rightAlign,
      render: (text) => text != null && parseInt(text) !== 0 ? <span className={`${s.rightAlign}`}>{text}</span> : <span className={s.rightAlign}>{text != null ? text : '--'}</span>,
    },
    {
      title: opu === 0 && type === 'schedule' ? 'Unplanned' : 'Not Assigned',
      dataIndex: opu === 0 && type === 'schedule' ? 'unplanned' : 'notAssigned',
      key: opu === 0 && type === 'schedule' ? 'unplanned' : 'notAssigned',
      className: s.rightAlign,
      render: (text) => text != null && parseInt(text) !== 0 ? <span className={`${s.rightAlign}`}>{text}</span> : <span className={s.rightAlign}>{text != null ? text : '--'}</span>,
    },
  ];

  const mapBreakdowns = (breakdowns: any[], isOpu: boolean) =>
    breakdowns.map((breakdown) => ({
      ...breakdown,
      key: isOpu ? breakdown.opu : breakdown.assetName,
    })
	);

  const dataSource = opu === 0
    ? (type === 'requirement'
			? mapBreakdowns(assuranceData?.requirementCompOpuBreakdowns ?? [], true)
			: mapBreakdowns(assuranceData?.scheduleCompOpuBreakdowns ?? [], true))
    : (type === 'requirement'
			? mapBreakdowns(assuranceData?.requirementCompAssetBreakdowns ?? [], false)
			: mapBreakdowns(assuranceData?.scheduleCompAssetBreakdowns ?? [], false))
      .filter((item) => item.assetName !== 'PETRONAS Floating LNG 3 Sdn Bhd' && ['PEGT', 'PGB'].includes(item.assetName));

  return (
    <div className={s.breakdownByOpusSection}>
      <div className={s.breakdownByOpusHeader}>
        {opu === 0 ? 'Breakdown By OPUs' : 'Breakdown By Assets'}
      </div>
      <Table
        className="assurance-table"
        rowClassName={(record, index) => index % 2 === 0 ? s.tableStripe : ''}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </div>
  );
};

export default FirstLineGBTableComponent;