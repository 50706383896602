import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import s from '../AssuranceLines.module.css';
import { fetchAvailablePeriodRequest, fetchGasBusinessTLA, fetchGCOTLA, fetchGPTLA, fetchLMTTLA, fetchLNGATLA } from "../../../../action/actionRisk";
import ThirdLineGBTableComponent from './ThirdLineGBTableComponent';
import ThirdLineActionItemComponent from './ThirdLineActionItemComponent';
import { Progress } from 'antd';

export interface AssuranceLineProps {
  data: {
    opu: number;
    line: number;
  };
  onChangeTab: (tab: number) => void;
  opuTabArr: { label: string, value: number }[];
  fromDate: {
    year: number;
    month: number;
  };
  toDate: {
    year: number;
    month: number;
  }
}

const AssuranceThirdLineComponent = ({ data, onChangeTab, opuTabArr, fromDate, toDate }: AssuranceLineProps) => {
  const dispatch = useDispatch();
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    setHasData(false); // Clear data when OPU changes
    switch (data.opu) {
      case 0:
        dispatch(fetchGasBusinessTLA(`?year=${fromDate.year}&fromMonth=${fromDate.month}&toMonth=${toDate.month}`));
        setHasData(true);
        break;
      case 1:
        dispatch(fetchLNGATLA(`?year=${fromDate.year}&fromMonth=${fromDate.month}&toMonth=${toDate.month}`));
        setHasData(true);
        break;
      case 2:
        dispatch(fetchGPTLA(`?year=${fromDate.year}&fromMonth=${fromDate.month}&toMonth=${toDate.month}`));
        setHasData(true);
        break;
      case 3:
      dispatch(fetchLMTTLA(`?year=${fromDate.year}&fromMonth=${fromDate.month}&toMonth=${toDate.month}`));
      setHasData(true);
        break;
      case 4:
        dispatch(fetchGCOTLA(`?year=${fromDate.year}&fromMonth=${fromDate.month}&toMonth=${toDate.month}`));
        setHasData(true);
        break;
      default:
        break;
    }
  }, [dispatch, data.opu, data.line, fromDate, toDate]);

  const ACAInfo = useSelector((state) =>
    get(state, 'risk.myAssuranceInfo.result.agreedCorrectiveAction', [])
  );

  const auditInfo = useSelector((state) =>
    get(state, 'risk.myAssuranceInfo.result.auditPlan', [])
  );

  const renderContent = (type: string) => {
    const info = type === "ACA" ? ACAInfo : auditInfo;
    console.log(info);
    if (!hasData || !info || info.length === 0) {
      return <div className={s.noResult}>
      <img src="/img/icon/no-result.svg" alt="No results" />
      <h2>No data available!</h2>
      </div>;
    }
    return (
      <div className={s.contentContainer}>
        <div className={s.flexContainer}>
          <ThirdLineActionItemComponent type={type}/>
        </div>
          <ThirdLineGBTableComponent
            assuranceData={info}
            opu={data.opu}
            onChangeTab={onChangeTab}
            opuTabArr={opuTabArr}
            type={type}
          />
      </div>
    );
  };

  const renderBase = () => {
    return (
      <>
        <div className={s.requirement}>
          <div className={s.header}>
            <span>Agreed Corrective Action (ACA)</span>
          </div>
          {renderContent("ACA")}
        </div>

        <div className={s.schedule}>
          <div className={s.header}>
            <span>Audit Plan</span>
          </div>
          {renderContent("Audit")}
        </div>
      </>
    );
  };

  return renderBase();
};

export default AssuranceThirdLineComponent;