import { useSelector } from 'react-redux';
import { get } from 'lodash';
import s from '../AssuranceLines.module.css';

export interface FirstLineActionItemProps {
  type: string;
}

const FirstLineActionItemComponent = ({ type }: FirstLineActionItemProps) => {
  const requirementAction = useSelector((state) =>
    get(state, 'risk.myAssuranceInfo.result.assuranceRequirementCompliance', [])
  );

  const scheduleAction = useSelector((state) =>
    get(state, 'risk.myAssuranceInfo.result.assuranceScheduleCompliance', [])
  );

  if (type === 'requirement') {
    return (
      <div className={s.actionItemsSection}>
        <div className={s.actionItemsHeader}>
          Action Items
        </div>
        <div className={`${s.actionItems} ${s.requirementAction}`}>
          <div className={`${s.actionItem} ${s.overdue}`}>
            <div className={s.bar}></div>
            <div>
              <p>Overdue</p>
              <span>{requirementAction?.overdueAction}</span>
            </div>
          </div>
          <div className={`${s.actionItem} ${s.notAssigned}`}>
            <div className={s.bar}></div>
            <div>
              <p>Not Assigned</p>
              <span>{requirementAction?.notAssignedAction}</span>
            </div>
          </div>
          <div className={`${s.actionItem} ${s.inProgress}`}>
            <div className={s.bar}></div>
            <div>
              <p>In Progress</p>
              <span>{requirementAction?.inProgressAction}</span>
            </div>
          </div>
          <div className={`${s.actionItem} ${s.closed}`}>
            <div className={s.bar}></div>
            <div>
              <p>Closed</p>
              <span>{requirementAction?.closedAction}</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={s.actionItemsSection}>
        <div className={s.actionItemsHeader}>
          Assurance Program
        </div>
        <div className={`${s.actionItems} ${s.scheduleAction}`}>
        <div className={`${s.actionItem} ${s.planned}`}>
            <div className={s.bar}></div>
            <div>
              <p>Planned</p>
              <span>{scheduleAction?.plannedProgram}</span>
            </div>
          </div>
          <div className={`${s.actionItem} ${s.conducted}`}>
            <div className={s.bar}></div>
            <div>
              <p>Conducted</p>
              <span>{scheduleAction?.conductedProgram}</span>
            </div>
          </div>
          <div className={`${s.actionItem} ${s.unplanned}`}>
            <div className={s.bar}></div>
            <div>
              <p>Unplanned</p>
              <span>{scheduleAction?.unplannedProgram}</span>
            </div>
          </div>
          <div className={`${s.actionItem} ${s.cancelled}`}>
            <div className={s.bar}></div>
            <div>
              <p>Cancelled</p>
              <span>{scheduleAction?.cancelledProgram}</span>
            </div>
          </div>
          
          <div className={`${s.actionItem} ${s.overdue}`} style={{ gridColumn: 'span 2' }}>
            <div className={s.bar}></div>
            <div>
              <p>Overdue</p>
              <span>{scheduleAction?.overdueProgram}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default FirstLineActionItemComponent;